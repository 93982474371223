input {
    width: 100%;
    margin: 5px;
}

input[type=text] {
    width: 100%;
    border: none;
    border-bottom: 2px solid #63afbd;
}

.search {
    align-items: center;
    margin: 20px;
    background-color: white;
}

.wrapper {
    background-color: white;
}

.searchbox {
    width: 400px;
    align-items: center;
    margin-right: 20px;
    padding: 20px;
}

.pagination__link {
    border: 1px solid #63afbd;
    background-color: #63afbd;
    padding: 10px;
    margin: 10px;
}

.pagination {
	display: flex;
	list-style: none;
	height: 40px;
	/* border: 1px solid rgb(121, 120, 120); */
	border-radius: 5px;
	width: fit-content;
	align-items: center;
	padding: 20px;
	margin-top: 20px;
}