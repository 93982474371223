@media only screen and (max-width: 600px) {
  .login-form {
    width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .login-form {
    width: 600px;
  }
}

.loginbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 2px grey; 
}

.btnFacebook { 
  width: 180px;
  height:50px;  
  border-radius: 4px;
  background: #3b5998;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin-right:20px;
  margin-bottom:20px;
  display: inline-block;
}
.btnGoogle { 
  width: 180px;
  height:50px;
  border-radius: 4px;
  color:white;
  border:0px transparent;
  text-align: center;
  display: inline-block;
}

.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.8;
}

.btnGoogle:hover {
  opacity: 0.8;
}